/* eslint-disable @typescript-eslint/no-unused-vars */
<template>
<div class="main">
   <section class="top-contact">
    
       <div class="wrapper">
         <div class="button">
           <div class="icon"><i class="fab fa-facebook-f"></i></div>
           <span>facebook</span>
         </div>
         <div class="button">
           <div class="icon"><i class="fab fa-twitter"></i></div>
           <span>twitter</span>
         </div>
         <div class="button">
           <div class="icon"><i class="fa fa-envelope"></i></div>
          <span><a href="mailto:medilab.alliedproductsltd@gmail.com">email</a> </span>
         </div>
         <div class="button">
           <div class="icon"><i class="fa fa-phone"></i></div>
           <span><a style="color:black;" href="tel:+254 721 811 499">+254721811499</a></span>
         </div>
         <div class="button">
           <div class="icon"><i class="fa fa-map-marker"></i></div>
           <span><a style="color:black;" target="_blank" href="https://www.google.com/maps/place/Starehe+Court/@-1.2768852,36.8352839,17z/data=!3m1!4b1!4m5!3m4!1s0x182f16cdbc9fd05f:0xaa79ee45ae361e9!8m2!3d-1.2768852!4d36.8374726">Starehe Court</a></span>
         </div>
       </div>

   </section>
<section>
   
    <img src="../assets/cologo.png" alt="" height="100" width="200" class="logo">
        <div id="nav" class="col-md-8 col-sm-8 col-lg-8">
         <scrollto href=".main" class="nav-link">Home</scrollto>
         <scrollto href="#products" class="nav-link">products</scrollto>
         <scrollto href="#about" class="nav-link">About</scrollto>
         <scrollto href="#contactus" class="nav-link">Contact us</scrollto>
                  
    </div>
      <div class="humburger" @click="myFunction(this)">
      <div class="bar1"></div>
      <div class="bar2"></div>
      <div class="bar3"></div>
    </div>  
</section>
</div>
</template>
<script>

import scrollto from '@/components/scrollto';

export default {
   components: {
            scrollto,
      
        },
    method:{}
}
</script>
<style lang="stylus" scoped>
.wrapper
    display: flex;
    position: relative;
    flex-direction: row;
    right: 40px;
    
.button
    height 40px
    width 40px
    margin-right 10px 
    border-radius 50px
    background white
    box-shadow 10px 10px 10px rgba(0,0,0,0.2)
    cursor pointer
    overflow hidden
    transition all 0.19s ease-in-out
.button span 
    font-size 16px
    font-weight 600
    line-height 50px
    
.button:hover
    width 200px

.button:nth-child(1):hover .icon{
  background #4267B2
  position relative
  color white
  left -37px
}
.button:nth-child(2):hover .icon
  position relative
  color white
  left -52px
  background #1DA1F2
.button:nth-child(3):hover .icon
  position relative
  color white
  left -57px
  background #90c4f9d7
.button:nth-child(4):hover .icon
  position relative
  color white
  left -24px
  background green
.button:nth-child(5):hover .icon
  position relative
  color red
  left -20px

  background white
.button:nth-child(4) span{
  color green
  text-decoration none
}
.button:nth-child(3) span{
  color red
}
.button:nth-child(1) span
  color #4267b2
.button:nth-child(2) span
  color #1DA1F2
.button .icon
    display inline-block
    height 35px
    width 35px
    text-align center
    border-radius 35px
  
.button .icon i{
  font-size 18px
  line-height 35px
}
.button a
  text-decoration none  
.temail
    display flex
    flex-wrap wrap
    flex-direction row
    position relative
    text-align left
    left: 70vw
.temail a   
    text-decoration none
    color grey
  
.top-contact
  background white
  width 100vw
  height 100px
  align-items center
  justify-content flex-end

section
  display flex
  flex-direction row
  background white
  height 100px
  width 100vw
.humburger
  display block
#nav
  flex-wrap wrap
  display flex
  flex-direction row
  justify-content flex-end
  background white
.nav-link
  color: black;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.15em;
  font-weight 700
  font-size 11px
  display: inline-block;
  padding: 19px 50px;
  position: relative;
  margin 0px 20px
.logo
  margin-right 20vw
  margin-top 0px
  height 120px
  width 175px
  position relative
  bottom 40px
  left 20px
 
.nav-link:after
  background: none repeat scroll 0 0 transparent;
  bottom: 39px;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background:#90c4f9d7;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;

.nav-link:hover:after 
  width: 100%; 
  left: 0; 

@media (max-width 1000px)
  .top-contact
      background white
      width 100vw
      height 52px
      align-items center
  .humburger
  
    width 20%

  section
    display flex
    flex-direction column
    background white
    height 125px
    width 100vw
  #nav
    flex-wrap wrap
    display flex
    flex-direction row
    justify-content space-evenly
    background white
  .nav-link
    color: #0099cd;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.15em;
    font-weight 700
    font-size 9px
    display: inline-block;
    padding: 5.5px 5px;
    position: relative;
    margin 0px
  .nav-link:after
    background: none repeat scroll 0 0 transparent;
    bottom: 39px;
    content: "";
    display: none;
    height: 2px;
    left: 50%;
    position: absolute;
    background: black;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;

  .nav-link:hover:after 
    width: 100%; 
    left: 0; 
  .logo
    height 60px
    width 90px
    position relative
    bottom 1px

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {opacity: 0;}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

@media (max-width 500px)
  .button
    height 30px
    width 30px
    margin-right 10px 
    border-radius 50px
    background white
    box-shadow 10px 10px 10px rgba(0,0,0,0.2)
    cursor pointer
    overflow hidden
    transition all 0.19s ease-in-out
  .button span 
    font-size 10px
    font-weight 500
    line-height 30px
    
  .button:hover
    width 120px
  .button .icon
    display inline-block
    height 30px
    width 30px
    text-align center
    border-radius 30px
  
  .button .icon i{
    font-size 11px
    line-height 30px
  }
  .button:nth-child(1):hover .icon{
  background #4267B2
  position relative
  color white
  left -15px
}
.button:nth-child(2):hover .icon
  position relative
  color white
  left -30px
  background #1DA1F2
.button:nth-child(3):hover .icon
  position relative
  color white
  left -30px
  background red
.button:nth-child(4):hover .icon
  position relative
  color white
  left -10px
  background green 
 
.button:nth-child(5):hover .icon
  position relative
  color white
  left -10px
  background red 

.wrapper
    display: flex;
    position: relative;
    flex-direction: row;
    right: 0px;
    bottom: 10px;
 
</style>