<template>
  <section class="sub">
      <div class="flex">
          <div class="service">
              <div class="content">
          <i  data-aos="fade-up" data-aos-duration="1300" style="font-size:50px; color:white" class="fas fa-hand-holding-water"></i>
            <p data-aos="fade-up" data-aos-duration="1500">
          We are a 
          trusted provider of 
          Water 
          Treatment products  
         </p>
              </div>
          </div>
          <div class="service">
              <div class="content">
                  
                  
                     <i  data-aos="fade-up" data-aos-duration="1300" style="font-size:50px; color:white;" class="fas fa-hospital-user"></i>
                       <p data-aos="fade-up" data-aos-duration="1500">
          Research facilities count on us for delivery of research products</p>
                  
              </div>
          </div>
          <div class="service">
              <div class="content">
          <i  data-aos="fade-up" data-aos-duration="1300" style="font-size:50px; color:white" class="fas fa-flask"></i>
            <p data-aos="fade-up" data-aos-duration="1500">
          we are a trusted supplier of laboratory effects</p>
              </div>
          </div>
      </div>

  </section>
</template>

<script>
export default {

}
</script>

<style scoped>
section{
    height:auto ;
    color: white;
    width: 100vw;
    position: relative;
    justify-content: center;
    align-content: center;
    padding: 0px;}
.flex p{
    color: white;
    position: relative;
    top: 10px;
    font-size: 14px;
}
.flex{
    display: flex;
    flex-flow: row wrap;
    gap: 0px;
    justify-items: center;
    position: relative;
    left: 0vw;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
}
.service:nth-child(1){
    width: 350px;
    height: 300px;
    background:lightblue ;
    border: none;
    border-radius: 4px;
    gap: 20px;
    line-height: 20px;
    color: black;

}
.service:nth-child(1){
    width: 350px;
    height: 250px;
    background:rgb(173, 230, 224) ;
    border: none;
    padding: 2.0%;
    border-radius: 0px;
    font-size: 12px;
    color: black;
}

.service:nth-child(2){
    width: 350px;
    padding: 2.0%;
    font-size: 12px;
    height: 250px;
    background:rgb(123, 206, 233) ;
    border: none;
    border-radius: 0px;
    color: black;
}

.service:nth-child(3){
    width: 350px;
    font-size: 12px;
    height: 250px;
    background:rgb(29, 171, 218) ;
    border: none;
    padding: 2%;
    border-top-right-radius: 0px;
    color: black;
}
.service:hover{
    box-shadow:0 10px 10px #90c4f9a3 ;
}

@media(max-width:500px)
{
    .flex{
    display: flex;
    flex-flow:row wrap ;
    gap: 0px;
    justify-content: center;
    position: relative;
    width: 100%;
    
    left: 0;
    right: 0;
    align-content: center;
}
@media screen and (min-width:1905px) {

    .flex{
    display: flex;
    flex-flow:row wrap ;
    gap: 0px;
    justify-items: center;
    position: relative;
    
    left:30vw;
    align-content: center;
}
}
}
</style>