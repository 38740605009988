<template>
  <main>
      <section class="products">
          <div class="water_treatment" data-aos="zoom-in-right" data-aos-duration="800" >
               <div class="grid" data-aos="fade-right" data-aos-duration="1800">
          <div class="grid-img">
              <img src="../assets/main/cooling tower.png" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Cooling Tower Regime</h2>
          </div>
      </div>
      <div class="grid" data-aos="fade-right" data-aos-duration="1500">
          <div class="grid-img">
              <img src="../assets/main/beta.png" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Chemicals</h2>
          </div>
          
      </div>
        <div class="grid" data-aos="fade-right" data-aos-duration="1500">
          <div class="grid-img">
              <img src="../assets/main/theta.png" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Water Treatment</h2>
          </div>
      </div>
      <div class="text">
          <h2>
              water <span>treatment</span>
          </h2>
      </div>
          </div>
    <div class="condition_monitoring" data-aos="flip-up" data-aos-duration="200" >
         <div class="grid" data-aos="fade-left" data-aos-duration="1500">
          <div class="grid-img">
              <img src="../assets/main/delta.png" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Condition Monitoring products</h2>
          </div>
      </div>
        <div class="grid" data-aos="fade-left" data-aos-duration="1500">
          <div class="grid-img">
              <img src="../assets/main/rho.png" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Condition Monitoring products</h2>
          </div>
      </div>
        <div class="grid" data-aos="fade-left" data-aos-duration="1500">
          <div class="grid-img">
              <img src="../assets/main/Touchcell.jpg" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Touchcell</h2>
          </div>
      </div>
       <div class="text">
          <h2>
              Condition <span>Monitoring</span>
          </h2>
      </div>
      
    </div>
    <div class="microbiology" data-aos="fade-left" data-aos-duration="600" >
               <div class="grid " id="5" data-aos="fade-right" data-aos-duration="1500">
          <div class="grid-img ">
              <img src="../assets/main/niu.png" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Microbiology/Bacteriology</h2>
          </div>
      </div>  
      <div class="grid" data-aos="fade-right" data-aos-duration="1500">
          <div class="grid-img">
              <img src="../assets/main/echo.png" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Serotyping Antisera</h2>
          </div>
      </div>
      <div class="grid" data-aos="fade-right" data-aos-duration="1500">
          <div class="grid-img">
              <img src="../assets/main/fox.png" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Antisera</h2>
          </div>
      </div>
      <div class="grid" data-aos="fade-right" data-aos-duration="1500">
          <div class="grid-img">
              <img src="../assets/main/green.png" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Antisera</h2>
          </div>
      </div>
      </div>
      <div class="spec-col"><span>
          <div class="grid" data-aos="fade-left" data-aos-duration="1500">
          <div class="grid-img">
              <img src="../assets/main/specimen.png" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Specimen Collection</h2>
          </div>
      </div>
      <div class="grid" data-aos="fade-left" data-aos-duration="1500">
          <div class="grid-img">
              <img src="../assets/main/ee.png" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Specimen Collection</h2>
          </div>
      </div>
      <div class="grid" data-aos="fade-left" data-aos-duration="1500">
          <div class="grid-img">
              <img src="../assets/main/ww.png" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Specimen Collection</h2>
          </div>
      </div>
      <div class="grid" data-aos="fade-left" data-aos-duration="1500">
          <div class="grid-img">
              <img src="../assets/main/www.png" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Specimen Collection</h2>
          </div>
      </div>
      <div class="grid" data-aos="fade-left" data-aos-duration="1500">
          <div class="grid-img">
              <img src="../assets/main/wwww.png" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Specimen Collection</h2>
          </div>
      </div>
      <div class="grid" data-aos="fade-left" data-aos-duration="1500">
          <div class="grid-img">
              <img src="../assets/main/wwwww.png" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Specimen Collection</h2>
          </div>
      </div>
      <div class="grid" data-aos="fade-left" data-aos-duration="1500">
          <div class="grid-img">
              <img src="../assets/main/wwwwwqq.png" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Specimen Collection</h2>
          </div>
      </div>
      <div class="grid" data-aos="fade-left" data-aos-duration="1500">
          <div class="grid-img">
              <img src="../assets/main/wwwwwww.png" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Specimen Collection</h2>
          </div>
      </div></span>
      </div>
      </section>
  </main>
</template>

<script>
export default {

}
</script>

<style scoped>
section{
    height: 350vh;
}
.water_treatment{
    width: 80%;
    height: auto;
    padding: 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    position: relative;
    background:#90c4f9 ;
}
.condition_monitoring{
    width: auto;
    height: auto;
    padding: 20px;
    display: flex;
    flex-direction: row-reverse;
    gap: 20px;
    margin-left: 25%;
    right: 0px;
    top: 70px;
    position: relative;
    background:#90c4f9 ;
}
.spec-col{
    width: 100vw;
    height: auto;
    padding: 20px;
    display: block;
    flex-direction: row;
    gap: 20px;
    margin-left: 0%;
    right: 0px;
    top: 150px;
    position: relative;
    background:#90c4f9 ;
    
  
}
.spec-col span{
    display: flex;
    flex-flow: row wrap;
      /* overflow: hidden; */
    gap:20px;
    position: relative;
    padding: 10px;
  
}

.spec-col .grid:nth-child(1){
 width:65%;
    
    grid-template-rows: repeat(3,190px);
    grid-template-columns:repeat(100px,0.7fr);
    grid-auto-flow: dense;
    
}
.microbiology{
    width: 70%;
    height: auto;
    padding: 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    top: 110px;
    position: relative;
    background:#90c4f9 ;
}
.text h2{
    display: block;
    font-weight: bolder;
    font-size: 2em;
    justify-content: center;
    align-content: center;
    color: white;
}
.overlay h2{
    font-size: 15px;
    color: black;
    font-weight: bold;
}
.overlay{
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: 0px;
    background-image:linear-gradient(to bottom, transparent, #90c4f9d7);
    padding:29px 15px 10px;
    transition: 0.5s;
}
.grid{
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    height: 320px;
    width: 260px;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.5);
   
    

}
.water_treatment .grid:nth-child(3){
    width: 460px;
}
.grid-img{
    width: 100%;
    height: 100%;
    transition: .5s;
}
img{
    height: 100%;
    width: 100%;
}
@media (max-width:500px) {
    .grid{
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    height: 160px;
    width: 130px;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.5);
   
    

}
span{

}
.spec-col{
        width: 100vw;
    height: auto;
    padding: 20px;
    display: block;
    flex-direction: row;
    gap: 20px;
    margin-left: auto;
    right: -87px;
    top: 150px;
    position: relative;
    background: #90c4f9;
}
.spec-col .grid:nth-child(1){
    width: 80%;
}
    section{
        height: 250vh;
    }
}
</style>