<template>
<main>
    <section>
        <h1>Our <div>Products</div></h1>
    </section>
  <section id="products" class="grid-section">
      
      <div class="grid" data-aos="fade-left" data-aos-duration="1500">
          <div class="grid-img">
              <img src="../assets/main/cooling tower.png" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Cooling Tower Regime</h2>
          </div>
      </div>
      <div class="grid" data-aos="fade-left" data-aos-duration="1500">
          <div class="grid-img">
              <img src="../assets/main/beta.png" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Chemicals</h2>
          </div>
      </div>
        <div class="grid" data-aos="fade-left" data-aos-duration="1500">
          <div class="grid-img">
              <img src="../assets/main/theta.png" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Water Treatment</h2>
          </div>
      </div>
        <div class="grid" data-aos="fade-left" data-aos-duration="1500">
          <div class="grid-img">
              <img src="../assets/main/delta.png" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Condition Monitoring products</h2>
          </div>
      </div>
        <div class="grid" data-aos="fade-left" data-aos-duration="1500">
          <div class="grid-img">
              <img src="../assets/main/rho.png" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Condition Monitoring products</h2>
          </div>
      </div>
        <div class="grid" data-aos="fade-left" data-aos-duration="1500">
          <div class="grid-img">
              <img src="../assets/main/Touchcell.jpg" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Touchcell</h2>
          </div>
      </div>
        <div class="grid " id="5" data-aos="fade-left" data-aos-duration="1500">
          <div class="grid-img ">
              <img src="../assets/main/niu.png" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Microbiology/Bacteriology</h2>
          </div>
      </div>  
      <div class="grid" data-aos="fade-left" data-aos-duration="1500">
          <div class="grid-img">
              <img src="../assets/main/echo.png" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Serotyping Antisera</h2>
          </div>
      </div>
      <div class="grid" data-aos="fade-left" data-aos-duration="1500">
          <div class="grid-img">
              <img src="../assets/main/fox.png" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Antisera</h2>
          </div>
      </div>
      <div class="grid" data-aos="fade-left" data-aos-duration="1500">
          <div class="grid-img">
              <img src="../assets/main/green.png" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Antisera</h2>
          </div>
      </div>
      <div class="grid" data-aos="fade-left" data-aos-duration="1500">
          <div class="grid-img">
              <img src="../assets/main/specimen.png" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Specimen Collection</h2>
          </div>
      </div>
      <div class="grid" data-aos="fade-left" data-aos-duration="1500">
          <div class="grid-img">
              <img src="../assets/main/ee.png" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Specimen Collection</h2>
          </div>
      </div>
      <div class="grid" data-aos="fade-left" data-aos-duration="1500">
          <div class="grid-img">
              <img src="../assets/main/ww.png" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Specimen Collection</h2>
          </div>
      </div>
      <div class="grid" data-aos="fade-left" data-aos-duration="1500">
          <div class="grid-img">
              <img src="../assets/main/www.png" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Specimen Collection</h2>
          </div>
      </div>
      <div class="grid" data-aos="fade-left" data-aos-duration="1500">
          <div class="grid-img">
              <img src="../assets/main/wwww.png" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Specimen Collection</h2>
          </div>
      </div>
      <div class="grid" data-aos="fade-left" data-aos-duration="1500">
          <div class="grid-img">
              <img src="../assets/main/wwwww.png" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Specimen Collection</h2>
          </div>
      </div>
      <div class="grid" data-aos="fade-left" data-aos-duration="1500">
          <div class="grid-img">
              <img src="../assets/main/wwwwwqq.png" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Specimen Collection</h2>
          </div>
      </div>
      <div class="grid" data-aos="fade-left" data-aos-duration="1500">
          <div class="grid-img">
              <img src="../assets/main/wwwwwww.png" alt="">
          </div>
          <div class="overlay" data-aos="fade-up" data-aos-duration="1500">
              <h2>Specimen Collection</h2>
          </div>
      </div>
      
  </section>
      
  </main>
</template>

<script>
export default {


}
</script>

<style scoped>
.overlay{
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: 0px;
    background-image:linear-gradient(to bottom, transparent, #90c4f9d7);
    padding:29px 15px 10px;
    transition: 0.5s;
}
main{
    display: block;
    text-align: left;

}
main h1{
    position: relative;
    left: 10vw;
}
main h1 div{
    height: 100px;
    background: #90c4f9d7;
    width: 100px;

}
.overlay h2{
    font-size: 15px;
    color: black;
    font-weight: bold;
}
img{
    height: 100%;
    width: 100%;
}

.grid-section{
    display: grid;
    width:75%;
    margin: 50px auto;
    grid-template-rows: repeat(3,190px);
    grid-template-columns:repeat(100px,0.7fr);
    grid-auto-flow: dense;
    grid-gap:20px;
}
.grid{
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.5);
}
.grid:hover{
     box-shadow: 10px 15px 20px rgba(0,0,0,0.5);
}
.grid-img{
    width: 100%;
    height: 100%;
    transition: .5s;
}
.grid:nth-child(3){
    grid-column: 3/5;
}
.grid:nth-child(1){
    grid-row: 1/2;
}
.grid:nth-child(7){
    grid-column: span 2;
}
.grid:nth-child(11){
    
    grid-column: span 4;
}
.grid:nth-child(18){
    grid-column: span 2;
}
@media (max-width:991px){
.grid-section{
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: center;
}
.grid{
    grid-column: unset;
    grid-row: unset;
    width: 150px;
    height: 150px;
    }
}
@media (max-width:501px){
.grid-section{
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: center;
}
.grid{
    grid-column: unset;
    grid-row: unset;
    width: 140px;
    height: 160px;
}
    }
.overlay h2{
    font-size: 11px;
    color: black;
    font-weight: bold;
}

@media (max-width:301px){
    .grid-section{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    }
    .grid{
        grid-column: unset;
        grid-row: unset;
        width: 80px;
        height: 80px;
        }
    .overlay h2{
    font-size: 9px;
    color: black;
    font-weight: bold;
}
}
</style>