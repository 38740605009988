/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable vue/no-unused-components */
/* eslint-disable vue/no-unused-vars */
/* eslint-disable @typescript-eslint/no-this-alias */
<template>
<v-layout class="main" style="font-family: 'Comfortaa', cursive; font-size:16px">
  <Nav/>
    <div class="home col-lg-12 col-md-12 col-sm-12  ">
      <div class="hero col-lg-4 col-md-12 col-sm-12">
      <span class="heading" >
       <transition name="fade">
          <h1 style="display:block; color:white; font-weight:700; font-size:3.7rem; text-align:left; margin-top:10px">
          welcome! <br>
         
        </h1>
       </transition>
        <p data-aos="slide-right" data-aos-duration="500" style="font-size: 1.25rem; color:white">Medilab & Allied 
          Products K Ltd is 
          located in Nairobi, 
          Kenya, a Chemical & 
          Research/Medical 
          Distributor</p>
      
         <!-- <scrollto data-aos="slide-up" data-aos-duration="1900"
                                        href="#products"
                                        class="nav-link"
                                    >Our products
                                    </scrollto> -->
      </span>
          <!-- <div class="card-1">
        <img src="..\assets\Medical_supplies_small.jpg" alt="" class="image">
      </div> -->
     
    </div>

    <!-- <div id="slider" >
        <img data-aos="fade-up" data-aos-duration="1900" src="../assets/main/lima.jpg" alt="" class="about_img" >
        <input type="radio" name="slider" id="s1" >
        <input type="radio" name="slider" id="s2" checked>
        <input type="radio" name="slider" id="s3">
        <input type="radio" name="slider" id="s4">
        <input type="radio" name="slider" id="s5">

        <label for="s1" id="slide1"><img data-aos="fade-right" data-aos-duration="500" src="../assets/main/lima.jpg" height="90%" width="90%" alt=""></label>
        <label for="s2" id="slide2"><img data-aos="fade-left" data-aos-duration="500" src="../assets/main/charlie.jpg" height="90%" width="90%" alt=""></label>
        <label for="s3" id="slide3"><img data-aos="fade-left" data-aos-duration="500" src="../assets/main/theta.png" height="100%" width="100%" alt=""></label>
        <label for="s4" id="slide4">4</label>
        <label for="s5" id="slide5">5</label>
        
    </div> -->
    

  <div class="carousel">
     <v-carousel
    style="height:85vh; border-radius:10px; position:relativee; " 
    cycle
    id="car"
   :show-arrows="false">
    <v-carousel-item 
      id="item"
      v-for="(item,i) in slides"
      :key="i"
      :src= "require('../assets/main/' + item)"
      
    >
        <div class="overlay">
         <v-row
         v-for="(item,i) in items"
      :key="i"
          class="fill-height"
          align="center"
          justify="center"
      
        >
          <!-- <div class="display-3"  data-aos="slide-right" data-aos-duration="1300">
            {{ item.text }} Slide
          </div> -->
        </v-row>
  </div>

    </v-carousel-item>


     </v-carousel></div>
  </div>
  <description/>
  <products-2/>
  <!-- <prod/> -->
  <!-- <products/> -->
  <about/>
  <contacus/>
  <Footer/>
</v-layout>

</template>

<script>
import products from '@/components/products.vue'
import About from '@/components/about.vue'
import Footer from '@/components/footer.vue'
import Contacus from '@/components/contacus.vue'
import Nav from '@/components/nav.vue'
import scrollto from '@/components/scrollto';
import Description from '@/components/description.vue'
import Prod from '@/components/prod.vue'
import Products2 from '@/components/products2.vue'


export default {
  components: {
    //  products,
   About, Footer, Contacus,Nav, Description,
    Products2,
    // Prod,
  },
  
    data () {
      return {
        colors: [
          'indigo',
          'warning',
          'pink darken-2',
          'red lighten-1',
          'deep-purple accent-4',
        ],
        slides: [
          "al.jpg",
          "charlie.jpg",
        
        ],
          items: [
       {text:"hello"},
       {text:"there"}
         
      ],
      }
    },


   
   
}

    
</script>

<style scoped>

body{
background-color:rgb(120, 241, 255);
}
.hero_img{
position: relative;
width:50%;
height: 30vw;
margin: 80px auto;
background: none;


}
.heading p{
  width: 500px;

}
.overlay{
    position: absolute;
    width: 100%;
    left: 0px;
    display: none;
    height: 100%;
    bottom: 0px;
    background-image:linear-gradient(to right, transparent, #90c4f9d7);
    padding:29px 15px 10px;
    transition: 0.5s;
}
#item{
  width: 100%;
  height: 100vh;
  z-index: -1000;
}
.carousel{
  height: 69%;
  width: 100%;
  border-radius: 15px;
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.268)
  ;
  padding: 0px;
  background: #90c4f9;
  z-index: 1000;
}
#slider {
  position: relative;
  width: 50%;
  height: 30vw;
  margin: 80px;
  font-family: 'Helvetica Neue', sans-serif;
  perspective: 1400px;
  transform-style: preserve-3d;

}

input[type=radio] {
  position: relative;
  top: 108%;
  opacity: .5;
  width: 18px;
  height: 18px;
  margin: 0 15px 0 0;
  opacity: 0.4;
  /* transform: translateX(-83px); */
  cursor: pointer;
    transition: all 1s ease-in-out;
}


input[type=radio]:nth-child(5) {
  margin-right: 0px;
}

input[type=radio]:checked {
  opacity: 1;
}




#slider label {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  color: white;
  font-size: 70px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
   transition: all 1s ease-in-out;
}

#slide1 {
  background: none;
}
#slide1 img{
  height: 100%;
  width:100% ;
}
#slide2 img{
  height: 100%;
  width:90vw ;
}
#slide2 {
  background: transparent;
}

#slide3 {
  background: dodgerblue;
}

#slide4 {
  background: slateblue;
}

#slide5 {
  background: violet;
}


/* Slider Functionality */

/* Active Slide */
#s1:checked ~ #slide1, #s2:checked ~ #slide2, #s3:checked ~ #slide3, #s4:checked ~ #slide4, #s5:checked ~ #slide5 {
  box-shadow: 0 13px 26px rgba(0,0,0, 0.3), 0 12px 6px rgba(0,0,0, 0.2);
  transform: translate3d(0%, 0, 0px);
}

/* Next Slide */
#s1:checked ~ #slide2, #s2:checked ~ #slide3, #s3:checked ~ #slide4, #s4:checked ~ #slide5, #s5:checked ~ #slide1 {
  box-shadow: 0 6px 10px rgba(0,0,0, 0.3), 0 2px 2px rgba(0,0,0, 0.2);
  transform: translate3d(15%, 0, -100px);
}


/* Next to Next Slide */
#s1:checked ~ #slide3, #s2:checked ~ #slide4, #s3:checked ~ #slide5, #s4:checked ~ #slide1, #s5:checked ~ #slide2 {
  box-shadow: 0 1px 4px rgba(0,0,0, 0.4);
  transform: translate3d(30%, 0, -250px);
}

/* Previous to Previous Slide */
#s1:checked ~ #slide4, #s2:checked ~ #slide5, #s3:checked ~ #slide1, #s4:checked ~ #slide2, #s5:checked ~ #slide3 {
  box-shadow: 0 1px 4px rgba(0,0,0, 0.4);
  transform: translate3d(-30%, 0, -250px);
}

/* Previous Slide */
#s1:checked ~ #slide5, #s2:checked ~ #slide1, #s3:checked ~ #slide2, #s4:checked ~ #slide3, #s5:checked ~ #slide4 {
  box-shadow: 0 6px 10px rgba(0,0,0, 0.3), 0 2px 2px rgba(0,0,0, 0.2);
  transform: translate3d(-15%, 0, -100px);
}
#hero{
  padding: 30px;
  width:500px ;
  height: 100%;
  position: relative;
  right: 0;
}

.home{
  height: 100vh;
  width: 100vw;
  background: #90c4f9;
  z-index: 1000;
  display: flex;
  gap: 10vw;
  flex-direction: row;
  padding: 50px 20px;
  overflow: auto;
}

.card-1{
  height: 500px;
  border-radius: 15px;
  background: white;
  width: 300px;
}
img{
  height: 80vh;
  /* width: 100vw; */
  position: relative;
  top: 0px;
  width: 600px;
}
#slide3 img{
  height: 80vh;
  /* width: 100vw; */
  position: relative;
  top: 0px;
  width: 100%;
  height: 100%;
}
.main{
  display: flex;
  flex-direction: column;

}
.hero{
     height: 20vh;
    width: 100%;
    position: relative;
    font-size: 300px;
    display: flex;
    flex-direction: row;
    padding: 30px;
    top: 100px;
}
.nav-link{
  font-size: 20px;
  background: white;
  padding: 10px 15px;
  color: #90c4f9;
  cursor: pointer;
  border-radius: 20px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.268);
}
p{
  font-size: 20px;
  text-align: left;
  flex-wrap:wrap ;
  display: flex;
}
/* .heading{
  width: 60vw;
  background: rgba(0, 0, 0, 0.207);
  height: 67vh;
  padding: 10px 30px;
  position: relative;
  border-radius:10px ;
  box-shadow: 0px 10px 10px rgba(255, 255, 255, 0.481);
} */

@media (max-width:1024px){
  #hero{
    display: none;
  }
  .main{
    width:100% ;
  }
  .hero_img{
    display: none;
  }
  .home::-webkit-scrollbar {
    display: none;
}
  .home{
    height: 100vh;
    width: 100vw;
    background: #90c4f9;
    z-index: 1000;
    display: flex;
    gap: 10vw;
    flex-direction: column-reverse;
    padding: 0 20px;
    overflow: hidden;
  }
  h1{
    font-size: 34px !important;
  }
  #p{
    font-size: 14px;
  }
  .nav-link{
    width: 50%;
    font-size:14px;
  }
  .hero{
    justify-content: center;
    align-content: center;
  }
  #slider {
    position: relative;
    width: 100%;
    height: 80vw;
    margin: 20px auto;
    font-family: 'Helvetica Neue', sans-serif;
    perspective: 1400px;
    transform-style: preserve-3d;

}
}
@media (max-width:500px){
  #hero{
    display: none;
  }
  .hero {
    height: 20vh;
    width: 100%;
    position: relative;
    font-size: 300px;
    display: flex;
    flex-direction: column-reverse;
    padding: 30px;
    top: 55%;
}
  .main{
    width:100% ;
  }
  .hero_img{
    display: none;
  }
  .home::-webkit-scrollbar {
    display: none;
}
  .home{
    height: 130vh;
    width: 100vw;
    background: #90c4f9;
    z-index: 1000;
    display: flex;
    gap: 10vw;
    flex-direction: column;
    padding: 0px;
    overflow: hidden;
  }
  h1{
    font-size: 34px !important;
  }
  #car{
    border-radius: 0px;
  }
  #p{
    font-size: 14px;
  }
  .nav-link{
    width: 50%;
    font-size:14px;
  }
  .carousel{
  height: 45%;
  width: 100%;
  border-radius: 1px;
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.268)
  ;
  position: relative;
  top: -18%;
  padding: 0px;
  background: #90c4f9;
  z-index: 1000;
}
.heading p{
  width: 300px;
  flex-wrap: wrap;

}
  .hero{
    justify-content: center;
    align-content: center;
  }
  #slider {
    position: relative;
    width: 100%;
    height: 80vw;
    margin: 20px auto;
    font-family: 'Helvetica Neue', sans-serif;
    perspective: 1400px;
    transform-style: preserve-3d;

}
}

</style>