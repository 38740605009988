<template>
  <section id="products">
      <h1 id="title" style="    position: relative;
    top: 40px;">Our <span data-aos="flip-up">Products</span></h1>
      <section id="water">
         <div class="container">
        <div class="text" data-aos="fade-right" data-aos-duration="1300" style="text-align:left;" >
                 <h1 style="font-weight:bold">Water Treatment</h1> <br>
                 <span style="font-weight:bold;">River Water Treatment Chemicals</span> <br>
                 <p>Coagulants, Flocculants, pH Correction, Disinfectants,</p>
                <br>
                <span style="font-weight:bold;">Reverse Osmosis Water Treatment Chemicals</span> <br>
              
                 <p>Anti-scaling, Biocide, CIP Chemicals /<p><br>
                 <span style="font-weight:bold;">Boiler Water Treatment Chemicals</span> <br>
                 <p>Corrosion Inhibitors, Scale Inhibitors, Oxygen Scavengers, Alkalinity Builders, Antifoam, Corrosion inhibitor for steam condensate return line </p><br>
                 <span style="font-weight:bold;">Closed System Water Treatment Chemicals</span> <br>
                 <p>Scale Inhibitors, Corrosion Inhibitors, Biocides, Frost protection, 	Antifoulants, Pre-commission cleaning chemicals</p>

             </div>
             <div class="gallery" >
                 <img  data-aos="fade-left" data-aos-duration="1300" src="../assets/main/theta.png" alt="">
                 <img data-aos="fade-left" data-aos-duration="1300" src="../assets/main/cooling tower.png" alt="">
                 <img data-aos="fade-left" data-aos-duration="1300" src="../assets/main/beta.png" alt="">
                 
             </div>
         </div>
      </section>
      <section id="condit">
          <div class="conta">
          <div class="text" data-aos="fade-right" data-aos-duration="1300" style="text-align:left;" >
                 <h1 style="font-weight:bold">Specimen Collection</h1>
                 <p data-aos="fade-right" data-aos-duration="1500" style="font-size:17px;">Sterile puritan flock swabs, Sterile 1.5ml &amp; 2ml tubes, Blood sampling, Stool, urine and water sampling bottles, Centrifuge 15ml and
50ml tubes etc</p>
             </div>
             <div class="gallery">
                 <img data-aos="fade-left" data-aos-duration="1300" src="../assets/main/specimen.png" alt="">
                 <img data-aos="fade-left" data-aos-duration="1500" src="../assets/main/ee.png" alt="">
                 <img data-aos="fade-left" data-aos-duration="1500" src="../assets/main/eee.png" alt="">
                 <img data-aos="fade-left" data-aos-duration="1700" id="cooling" src="../assets/main/ww.png" alt="">
                 <img data-aos="fade-left" data-aos-duration="1900"  src="../assets/main/wwwww.png" alt="">
                 <img data-aos="fade-left" data-aos-duration="1500" src="../assets/main/www.png" alt="">
                 <img data-aos="fade-left" data-aos-duration="1500" src="../assets/main/wwww.png" alt="">
                 <img data-aos="fade-left" data-aos-duration="2100" id="cooling" src="../assets/main/wwwwwqq.png" alt="">
                 <img data-aos="fade-left" data-aos-duration="2300"  src="../assets/main/wwwwwww.png" alt="">
                 
             </div>
         </div>
      </section>
      <section id="micro">
          <div class="conta2">
              <div class="text"  data-aos="fade-right" data-aos-duration="1300" style="text-align:left;">
                    <h1  style="font-weight:bold">Condition Monitoring</h1>
                 <p data-aos="fade-right" data-aos-duration="1500" style="font-size:17px;">Water in Oil Testing (WIO), Base Number BN testing, Insolubles Testing (Combustion related debris and oxidation products)</p>
              </div>
              <div class="gallery">
                 <img data-aos="fade-left" data-aos-duration="1300" src="../assets/main/delta.png" alt="">
                  <img data-aos="fade-left" data-aos-duration="1500" src="../assets/main/rho.png" alt="">
                  <img data-aos="fade-left" data-aos-duration="1500" src="../assets/main/same.png" alt="">
                 <img data-aos="fade-left" data-aos-duration="1700" id="cooling" src="../assets/main/Touchcell.jpg" alt="">
              </div>
          </div>

      </section>
      <section id="spec_col">
          <div class="conta3">
              <div class="text"  data-aos="fade-left" data-aos-duration="1300" style="text-align:left;">
                     <h1 style="font-weight:bold">Microbiology</h1>
                 <p data-aos="fade-right" data-aos-duration="1500" style="font-size:17px;">Serotyping Antisera- Bacteria Isolation and Identification</p>

              </div>
                <div class="gallery">
                  <img src="../assets/main/niu.png" alt="">
                 
                  <img data-aos="fade-left" data-aos-duration="1300" src="../assets/main/echo.png" alt="">
                 <img data-aos="fade-left" data-aos-duration="1500" id="cooling" src="../assets/main/green.png" alt="">
                 <img data-aos="fade-left" data-aos-duration="1700" id="cooling" src="../assets/main/fox.png" alt="">
              </div>
          </div>

      </section>
  </section>
</template>

<script>
export default {

}
</script>

<style scoped>
.txt{
    width: 500px;
}

#products{
    height: auto;
    text-align: center;
    padding-left: 20px;
}
#products #title{
    position: relative;
    top: 40px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
}
#condit{
    height: 110vh;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;


}
#products h1 span{
    background:#90c4f9d7 ;
    padding: 10px;
    color: white;
}
#micro{
    height: 130vh;
    justify-content: center;
    align-items: center;
    
}
#water{
    height: 100vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    
}
#water .container{
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
#spec_col{
    height: 70vh;
    
}
div{
    height: 50vh;
    width: 100vw;
}
.div:nth-child(1){
    background: yellow;

}
.div:nth-child(2){
    background: yellow;
    
}
.grid-img{
    width: 100%;
    height: 100%;
    transition: .5s;
    z-index: 100;
}
#water .container .gallery img{
    height: 170px;
    width: 16.5vw;
    border-radius: 8px;
    box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.26);
    
}
#water .container .gallery img:nth-child(1){
    height: 230px;
    width: 100%;

}
#water .text{
    width: 500px;
    padding: 25px;
    height: auto;
    position: relative;
    top: 30%;
    left: 0px;
}
#water .container .gallery{
    width: 34.5vw;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    float: right;
    gap: 20px;
    position: relative;

}
.conta2 .text{
    width: 500px;
    padding: 25px;
    height: auto;
    position: relative;
    top: 5%;
    left: 0px;
}

.conta2{
    display:flex;
    flex-direction: row;
    justify-content:center;
    gap: 1px;
    position: relative;
    top: 50vh;
    left: 0vw;


}
.conta{
    display:flex;
    flex-direction: row-reverse;
    justify-content:center;
    gap: 70px;


}
.conta3{
    display:flex;
    flex-direction: row-reverse;
    justify-content:center;
    gap: 150px;
    position: relative;
    top: 70px;

}
.conta .gallery{
    display: flex;
    gap: 12px;
    position: relative;
    left: 100px;
    float: right;
    flex-wrap: wrap;
    max-width:700px;
    transition: all 0.8 ease-in;
    
}
.conta2 .gallery{
    display: flex;
    gap: 20px;
    float: right;
    flex-wrap: wrap;
    max-width:37vw;
    transition: all 0.8 ease-in;
    
}
.conta3 .gallery{
    width: 37vw;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    float: right;
    gap: 20px;
    
}
.conta .gallery img{
    width: 170px;
    height: 170px;
    float: right;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.46);
    border-radius: 10px;
    transition: all 0.8s ease-in;
}
.conta2 .gallery img{
    width: 11.29vw;
    height: 160px;
    float: right;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.46);
    transform: translateY(20px);
    transition: all 0.8s ease-in;
}
.conta3 .gallery img{
    height: 170px;
    width: 10.79vw;
    border-radius: 8px;
    box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.26);
}
.conta3 .gallery img:nth-child(1){
    width: 94%;
    height: 300px;
    float: right;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.46);
    transition: all 0.8s ease-in;
}

.conta3 .gallery img:hover{

  
    z-index: 100;
    border-radius:10px ;
    transition: all 0.8s ease-in;
    animation: fadeIn ease 0.8s;
}
@keyframes fadeIn {
  0% {
    opacity:0.6;
  }
  100% {
    opacity:1;
  }
}
.conta .gallery img:hover{
    transform:scale(1.3);
    z-index:1000;
    border-radius: 8px;
    animation: fadeIn ease 0.8s;
}

.conta .gallery img:nth-child(1){
    width: 535px;
    transform: translateY(20px);
    height: 250px;
    position: relative;

    top: -20px;
    transition: all 0.8 ease-in;
}
.conta2 .gallery img:nth-child(1){
    width: 100%;
    height: 270px;
    position: relative;
    top: -10px;
    transition: all 0.8 ease-in;
}
.conta .gallery img:nth-child(9){
    width: 50%;
    height: 170px;
}




#condit .container .gallery{
    float: left;
    height: 300px;
    width: 300px;
    position: relative;
    left: -13.5%;
    top: -150px;
    display: block;
}
#condit .container .text{
        width: 500px;
    padding: 25px;
    height: auto;
    position: relative;
    top: 30%;
    float: right;
}



#condit .container .container .gallery #cooling:hover{
    transform:scale(1.3);
    z-index:100000;
    border-radius: 8px;
}

#condit .container .gallery img:hover{
    z-index: 1000  !important;
}

.container .gallery{
    display: grid;
}
.content{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
}
.condit .gallery{
    float: left;
    height: 300px;
    width: 300px;
    position: relative;
    left: 2%;
    display: block;
}
.text{
    width: 500px;
    padding: 25px;
    height: auto;
    position: relative;
    left: -50px;
}

@media (max-width:500px) {
    @-ms-viewport { width: 320px; }
    .text p{
        font-size: 10px !important;
        
    }

    /* water section */
    #products{
        display: flex;
        padding-left: 0px;
        gap:70px;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    #water{
        height: 110vh;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
    #water div.container{
        height: 100vh;
        position: relative;
        width: 100vw;
        /* border-top: 1px solid #90c4f9;
        border-bottom: 1px solid #90c4f9; */
        top: 0%;
        gap: 20px;
        position: relative;
        /* top: 100px; */
        display: flex;
        justify-content: space-evenly;
        align-content: center;
        flex-wrap: wrap;
        flex-direction: row;
        }
    
    #water div.text{
        width: 380px;
        padding: 25px;
        height: auto;
        font-size: 10px;
        position: relative;
        top: 0%;
        left: 0px;
        text-align: center;
    }
      #water div.text h1{

        font-size: 25px;
        position: relative;
    }
     #water .container .gallery{
        width: 87.5vw;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        float: right;
        gap: 20px;
        position: relative;
        bottom: 0vh;
     }
    #water .container .gallery img{
        height:35%;
        width: 45%;
        border-radius: 8px;
        box-shadow: 0px 7px 7px rgb(0 0 0 / 26%);
    }
    #water .container .gallery img:nth-child(1) {
        height: 230px;
        width: 96%;
    }
    /* end */
    /* conta */
    .conta{
        height: 170vh;
        position: relative;
        padding: 20px;
        width: 100vw;
        /* border-bottom: 1px solid #90c4f9; */
        top: 0%;
        display: flex;
        flex-direction: column;
    }
    .conta .gallery{
        width: 87.5vw;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        float: right;
        gap: 20px;
        top: -120px;
        left: 5px;
    }
    .conta .gallery img{
        height: 35%;
        width: 46%;
        border-radius: 8px;
        box-shadow: 0px 7px 7px rgb(0 0 0 / 26%);
    }
     .conta .gallery img:nth-child(1){
         height: 170px;
         width: 98%;
         left: 0px;
         position: relative;
         top: -30px;
     }
    .conta .gallery img:nth-child(6){
         height: 35%;
         
         left: 0px;
         grid-auto-columns: 1/2;
     }
    .conta .gallery img:nth-child(9) {
        width: 46%;
        height: 35%;
    }
    .conta .text{
        width: 350px;
        padding: 25px;
        height: auto;
        font-size: 10px;
        position: relative;
        top: -7%;
        display: flex;
        text-align: left;
        left: -20px;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: stretch;
        align-items: flex-start;
    
    }
    .conta .text h1{
        font-size: 23px;
    }
      .conta .text p{
        font-size: 13px;
    }
    /* end */
    /* conta2 */
    .conta2 {
        height: 130vh;
        padding: 10px;
        gap: 0px;
        position: relative;
        width: 100vw;
        /* border-bottom: 1px solid #90c4f9; */
        top: 5%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-evenly;
    }
    .conta2 .gallery{
        gap: 30px 10px;
        display: flex;
        gap: 12px;
        position: relative;
        top: -110px;
        justify-content: center;
        float: right;
        flex-wrap: wrap;
        max-width:94vw;
        transition: all 0.8 ease-in;
        left: 5px;
    }
    .conta2 .gallery img{
        width: 45%;
        height: 37%;
        border-radius: 8px;
    }
    .conta2 .text{
        width: 100%;
        padding: 25px;
        height: auto;
        position: relative;
        top: 1%;
        left: -10px;
        text-align: left;
    }
    .conta2 .text h1{
        font-size: 23px;
    }
    .conta2 .text p{
        font-size: 13px;
    }
    .conta2 .gallery img:nth-child(1){
         height: 250px;
         width: 92%;
         left: 0px;
       
     }
     /* end */
     /* conta3 */
    .conta3 {
        height: 100vh;
        padding: 10px;
        position: relative;
        width: 100vw;
        /* border-bottom: 1px solid #90c4f9; */
        top: 0%;
        display: flex;
        flex-direction: column;
    }
    .conta3 .gallery img{
        width:170px;
        height:160px;
    }
    .conta3 .gallery {
        width: 94.5vw;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        float: right;
        position: relative;
        justify-content: center;
        gap: 20px;
        top: -150px;
        left: 5px;
    }
    .conta3 .text{
        width: 100%;
        padding: 25px;
        height: auto;
        font-size: 10px;
        position: relative;
        top: 0%;
        left: -10px;
    }
    .conta3 .text h1{
        font-size: 23px;
    }
    .conta3 .text p{
        font-size: 13px;
    }
    .conta3 .gallery img:nth-child(1){
         height: 250px;
         width: 92%;
         left: 0px;
         position: relative;
         top: 0px;
     }
    .conta3 .gallery img:nth-child(2) {
        width: 45%;
        height: 37%;
        float: right;
        transform: translateY(0px) translateX(0px);
        box-shadow: 0 3px 10px rgb(0 0 0 / 46%);
        transition: all 0.8s ease-in;
    }

    .conta3 .gallery img:nth-child(3){
       width: 45%;
        height: 37%;
        float: right;
        transform: translateY(0px)  translateX(0px);
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.46);
        
        transition: all 0.8s ease-in;
    }

    .conta3 .gallery img:nth-child(4){
        width: 45%;
        height: 37%;
        float: right;
        transform: translateY(0px)  translateX(2px);
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.46);
        
        transition: all 0.8s ease-in;
    }
    /* end */
    }
   @media screen and (min-width:1905px) {

    #water .container .gallery{
        width: 24.5vw;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        float: right;
        gap: 20px;
        position: relative;
        top: 0px;
        align-items: center;
        align-content: center;
     }
    #water .container .gallery img{
        width: 11vw;
        height: 170px;
    }
    #water .container .gallery img:nth-child(1) {
        height: 230px;
        width: 23vw;
    }
    #water .container{
   
    gap: 20px;
    position: relative;
    top: 100px;
    justify-content: space-evenly;
    align-content: center;
    flex-wrap: wrap;
    gap: 100px;
    flex-direction: row;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;

     }
     .conta2 .gallery img{
    width: 11.6vw;
    height: 180px;
    float: right;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.46);
    transform: translateY(20px);
    transition: all 0.8s ease-in;
    }
    #water {
        /* width: 107.5vw;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        float: right;
        gap: 20px;
        position: relative;
        bottom: 200px */
     }
   }

</style>